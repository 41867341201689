import { freeCoursesCategory, learningPathCategory } from 'components/Catalog/store'
import { allCoursesId, BASE_URL_WEB, categoriesColor, OnboardingVideos, paymentCardTypes, ProfileTypes, RecaptchaSiteKey, showLearningPath, videoSources } from 'constants/'
import fetch from 'core/fetch'
import moment from 'moment'
import { Icons, neutral } from '@alphorm/ui'
import { getQueryParam, queryParams } from 'components/common/UTMscript'
import { isObject } from 'lodash'

export const DetailedDateFormat = 'DD/MM/YY - HH:mm (Z)'
export const DateFormat = 'DD/MM/YYYY'
export const YMDDateFormat = 'YYYY-MM-DD'
export const DMYDateFormat = 'D MMMM YYYY'
export const DMDateFormat = 'DD MMMM'
export const MMMMDateFormat = 'MMMM'
export const TimeZoneDateFormat = 'D/MM/YYYY HH:mm (Z)'

const earlyValidDate = '2000-01-01'

export const formatSecondsDuration = durationInSeconds => {
  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = duration.days() * 24 + duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  return `${hours}h${minutes}min${seconds}s`
}

export const formatSecondsDurationFromAlpormJS = durationInSeconds => {
  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = duration.days() * 24 + duration.hours()
  const minutes = duration.minutes()
  return `${hours ? hours + 'h' : ''}${minutes ? minutes + 'min' : ''}`
}
export const formatSecondsToHoursRounded = durationInSeconds => {
  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = duration.days() * 24 + duration.hours()
  const minutes = duration.minutes()
  return minutes < 30 ? hours : hours + 1
}

export const nextYear = _ => moment().add(1, 'year').format(DateFormat)
export const nextMonth = _ => moment().add(1, 'month').format(DateFormat)
export const addDays = (days = 0, date = new Date()) => moment(date).add(days, 'days').format(DateFormat)
export const formatSecondsToISO8601 = (durationInSeconds = 0) => moment.duration(durationInSeconds, 's').toISOString()
export const formatDateYMD = date => moment(date).format(YMDDateFormat)
export const formatDateDMY = date => moment(date).format(DateFormat)
export const beforeToday = (date, daysToAdd = 0) => moment(date).add(daysToAdd, 'd').isBefore(moment(new Date()), 'days')
export const isValidDate = date => moment(date).isAfter(earlyValidDate)
export const formatCertificateDate = date => moment(date, moment.ISO_8601).locale('fr').format(DMYDateFormat)
export const stripHtml = (htmlString = '') => htmlString ? htmlString.replace(/<[^>]+>|(&nbsp;)+/g, '') : ''

const removeEmptyLines = (string = '') => string.replace(/(\r\n|\n|\r)/gm, '')

export const escapeRegExp = string => removeEmptyLines(string).replace(/["\\]/g, '\\$&')

export const steps = {
  onBoardingProfileStep: 0,
  onBoardingSubscriptionStep: 1,
  onBoardingSuccessStep: 2,
  onBoardingCPFStep: 3,
  onBoardingCPFSuccessStep: 4,
  onBoardingB2BSubscription: 5,
  onBoardingB2BNew: 6,
  onBoardingFreePeriod: 7
}

export const OnboardingInnerSteps = {
  subscriptionChoiceStep: 7,
  paymentMethodsStep: 8,
  paymentSuccessStep: 9,
  AddressStep: 10,
  threeTimePayment: 11
}

export const getCaptchaToken = () =>
  new Promise(resolve => {
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(RecaptchaSiteKey, { action: 'submit' })
      resolve(token)
    })
  })

export const AddToCartDataLayer = ({ item }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        item
      ]
    }
  })
}

export const RemoveFromCartDataLayer = ({ item }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        item
      ]
    }
  })
}

export const CheckoutDataLayer = ({ items }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items
    }
  })
}

export const PurchaseDataLayer = ({ items, currency, value, tax, affiliation, coupon }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency,
      value,
      tax,
      shipping: 0,
      affiliation,
      coupon,
      items
    }
  })
}

export const DeletePayementMedthodDataLayer = ({ paymentType, userId }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'remove_payment_info',
    user_id: userId,
    payment_type: paymentType
  })
}

export const CancelSubscription = ({ subscriptionType, userId }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'cancel_subscription',
    user_id: userId,
    payment_type: subscriptionType
  })
}

export const ReactivateSubscription = ({ subscriptionType, userId }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'reactivate_subscription',
    user_id: userId,
    payment_type: subscriptionType
  })
}

export const TutorialBegin = ({ accountType, userId, courseTitle }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'tutorial_begin',
    user_id: userId,
    account_type: accountType,
    courseTitle
  })
}

export const TutorialComplete = ({ accountType, userId, courseTitle }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'tutorial_complete',
    user_id: userId,
    account_type: accountType,
    courseTitle
  })
}

export const LoginDataLayer = ({ loginMethod, userId }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'login',
    login_method: loginMethod,
    user_id: userId,
    userId: userId
  })
}

export const SignUpDataLayer = ({ loginMethod, userId }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'sign_up',
    login_method: loginMethod,
    user_id: userId,
    userId: userId
  })
}

export const ViewItemListDataLayer = ({ courses, listName }) => {
  const formatedData = courses.map((course, index) => {
    return {
      item_name: course.Title,
      item_id: course.Code,
      price: course.Price,
      item_list_name: listName || 'all_courses',
      index: index,
      quantity: '1'
    }
  })
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: [
        ...formatedData
      ]
    }
  })
}

export const SelectItemDataLayer = ({ item, listName, catalog }) => {
  let formatedData = {}
  if (catalog) {
    formatedData = {
      item_name: item.Title,
      item_id: item.Code,
      price: item.Price,
      item_category: listName || 'all_courses'

    }
  } else {
    formatedData = {
      item_name: item.Title,
      item_category: listName || 'all_courses'
    }
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [formatedData]
    }
  })
}

export const ViewCartDataLayer = ({ courses }) => {
  const formatedData = courses.map((course) => {
    return {
      item_id: course.Code,
      price: course.Price,
      item_name: course.Title,
      quantity: '1'

    }
  })
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      items: [
        ...formatedData
      ]
    }
  })
}

export const ViewItemDataLayer = ({ item }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [{
        item_name: item.Title,
        item_id: item.Code,
        price: item.Price,
        quantity: '1'
      }]
    }
  })
}

export const CompleteOnboardingDataLayer = ({ userId, userSubscription }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    user_id: userId,
    event: 'complete_onboarding',
    subscription: userSubscription || 'NONE'
  })
}

const verifyPhoneNumberQuery = phoneNumber => `${BASE_URL_WEB}/phone/${phoneNumber}`
const verifyCodeQuery = (phoneNumber, code) => `${BASE_URL_WEB}/phone/${phoneNumber}/${code}`

export const verifyPhoneNumber = async (phoneNumber) => {
  const response = await fetch(verifyPhoneNumberQuery(phoneNumber), { method: 'post' })
  return response
}

export const verifyCode = async (phoneNumber, code) => {
  const response = await fetch(verifyCodeQuery(phoneNumber, code))
  return response
}

export const checkCustomProtocol = (inProtocol, goToDownload, inTimeOut) => {
  const isMicrosoftEdge = !!navigator.msLaunchUri
  if (isMicrosoftEdge) {
    navigator.msLaunchUri(inProtocol, _ => null, _ => goToDownload())
  } else {
    let timeout = inTimeOut
    window.addEventListener('blur', _ => window.clearTimeout(timeout))
    timeout = window.setTimeout(goToDownload, inTimeOut)
    window.location = inProtocol
  }
}

export const detectPlatform = () => {
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const os = {
    macos: false,
    windows: false,
    linux: false
  }

  if (macosPlatforms.indexOf(platform) !== -1) {
    os.macos = true
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os.windows = true
  } else if (/Linux/.test(platform)) {
    os.linux = true
  }

  return os
}

export const detectVideoOfOnboarding = ({ b2bUser = false, newB2bUser = false, profileType, subscriptionExist = false, subscriptionValid = false }) => {
  switch (true) {
    case newB2bUser:
      return OnboardingVideos.InvitedB2BLearner
    case b2bUser && !newB2bUser && (!subscriptionExist || !subscriptionValid):
      return OnboardingVideos.B2BLearnerWithoutLicense
    case b2bUser && !newB2bUser && subscriptionExist && subscriptionValid:
      return OnboardingVideos.B2BLearnerWithLicense
    case !b2bUser && profileType === ProfileTypes.BasicSubscriber:
      return OnboardingVideos.BasicSubscriber
    case !b2bUser && profileType === ProfileTypes.Buyer:
      return OnboardingVideos.Buyer
    default:
      return OnboardingVideos.FreeSubscriber
  }
}

export const injectValuesInString = (str, variables = {}) => {
  Object.entries(variables).forEach(([key, value]) => {
    str = str.replaceAll(`{{${key}}}`, value)
  })

  return str
}

export const onBoardingGenerateStepsMap = ({ initial, transitions }) => {
  const stepsMap = [initial]

  const generateSteps = (currentTransition) => {
    if (!currentTransition.next) return stepsMap

    const next = typeof currentTransition.next === 'function' ? currentTransition.next() : currentTransition.next

    stepsMap.push(next)
    return generateSteps(transitions[next])
  }

  return generateSteps(transitions[initial])
}

export const onBoardingGetStepsWithoutNext = ({ transitions }) => {
  return Object.entries(transitions)
    .filter(([_, { next, noSkipAll }]) => next === undefined || noSkipAll)
    .map(([step]) => step)
}

export const catalogTitle = ({
  selectedCategoryId,
  selectedCategoryName,
  freeCategoryTitle,
  freeCategoryLabel,
  learningPathsTitle,
  learningPathsLabel,
  coursesTitle,
  coursesLabel,
  numberOfCourses,
  numberOfLearningPaths
}) => {
  switch (selectedCategoryId) {
    case freeCoursesCategory.Id:
      return {
        categoryTitle: freeCategoryTitle,
        selectedCategoryInfo: [{
          icon: Icons.video,
          text: `${numberOfCourses} ${freeCategoryLabel}`
        }]
      }
    case learningPathCategory.Id:
      return {
        categoryTitle: learningPathsTitle,
        selectedCategoryInfo: [{
          icon: Icons.path,
          text: `${numberOfLearningPaths} ${learningPathsLabel}`
        }]
      }
    case allCoursesId:
      return showLearningPath
        ? {
            categoryTitle: coursesTitle,
            selectedCategoryInfo: [{
              icon: Icons.video,
              text: `${numberOfCourses} ${coursesLabel}`
            },
            {
              icon: Icons.path,
              text: `${numberOfLearningPaths} ${learningPathsLabel}`
            }]
          }
        : {
            categoryTitle: coursesTitle,
            selectedCategoryInfo: [{
              icon: Icons.video,
              text: `${numberOfCourses} ${coursesLabel}`
            }]
          }
    default:
      return showLearningPath
        ? {
            categoryTitle: selectedCategoryName,
            selectedCategoryInfo: [{
              icon: Icons.video,
              text: `${numberOfCourses} ${coursesLabel}`
            },
            {
              icon: Icons.path,
              text: `${numberOfLearningPaths} ${learningPathsLabel}`
            }]
          }
        : {
            categoryTitle: selectedCategoryName,
            selectedCategoryInfo: [{
              icon: Icons.video,
              text: `${numberOfCourses} ${coursesLabel}`
            }]
          }
  }
}

export const checkUTMsInURL = (url, utms) => {
  try {
    const filteredQueryArray = queryParams.map((queryParam) => ({
      queryParam,
      queryParamValue: getQueryParam(queryParam)
    }))
    const utmsArrayFiltered = queryParams.map((queryParam) => ({
      queryParam,
      queryParamValue: getQueryParam(queryParam, utms)
    }))
    return JSON.stringify(filteredQueryArray) === JSON.stringify(utmsArrayFiltered)
  } catch (e) {
    return false
  }
}

export const generateScheduleData = (isTrial, price) => {
  return {
    Billings: [
      {
        Date: moment(),
        isPaid: !isTrial
      },
      {
        Date: moment().add(1, 'M'),
        isPaid: false
      },
      {
        Date: moment().add(2, 'M'),
        isPaid: false
      }
    ],
    PaymentAmount: price
  }
}

export const userProfileType = (userProfileType, profileTypesReferential) => {
  return userProfileType && profileTypesReferential ? ProfileTypes[profileTypesReferential[userProfileType]] : null
}

export const videoPlayerQualities = (videoLink, selectedQuality) => {
  if (isObject(videoLink)) {
    const qualities = [
      {
        src: videoLink.Desktop,
        type: 'video/mp4',
        label: videoSources.HD
      },
      {
        src: videoLink.Tablet,
        type: 'video/mp4',
        label: videoSources.SD
      },
      {
        src: videoLink.Phone,
        type: 'video/mp4',
        label: videoSources.LD
      }
    ]
    if (videoLink.FullHd) {
      qualities.unshift({
        src: videoLink.FullHd,
        type: 'video/mp4',
        label: videoSources.FHD
      })
    }
    qualities.map(quality => quality.label === selectedQuality && (quality.selected = true))
    return qualities
  } else return { src: videoLink, type: 'video/mp4' }
}

export const splitPayments = cards => {
  const cmiPaymentMethod = cards.filter(item => item.PaymentMethodType === paymentCardTypes.CmiCard)
  const otherPaymentMethods = cards.filter(item => item.PaymentMethodType !== paymentCardTypes.CmiCard)
  return [cmiPaymentMethod, otherPaymentMethods]
}

export const nameShortener = ({ firstName = '', lastName = '' }) => `${firstName} ${lastName.charAt(0)}.`
export const nameInitials = ({ firstName = '', lastName = '' }) => `${firstName.charAt(0)}${lastName.charAt(0)}`

export const calculateTimeLeft = (plannedDate) => {
  const difference = moment(plannedDate).diff(moment())
  const duration = moment.duration(difference)
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return { days, hours, minutes, seconds }
}

export const getWebinarStatus = (WebinarStatus, status) => {
  if (status === WebinarStatus?.Live) return { text: 'Live', state: 'live' }
  if (status === WebinarStatus?.Coming) return { text: 'A venir', state: 'coming' }
  if (status === WebinarStatus?.Passed) return { text: 'Passé', state: 'passed' }
}

export const getCategoryDetailsByIds = ({ categories = [], categoryIds = [] }) => {
  try {
    return categoryIds.map(categoryId => {
      const category = categories.find(category => category.Id === categoryId)
      return {
        ...category,
        BackgroundColor: categoriesColor[categoryId]?.backgroundColor || neutral[0]
      }
    })
  } catch (e) {
    return []
  }
}
