import { createUseStyles, useTheme } from 'react-jss'
import style from './style'
import Text from './text.json'
import Layout from 'components/common/Layout'
import {
  TypographyElement,
  Button,
  Card,
  Paper,
  Row,
  Icons,
  Icon,
  Avatar,
  AdCard,
  Container,
  MediaQuery,
  Skeleton,
  Col
} from 'UI/'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CertificateNeutralPath, CourseNeutralPath, CatalogPath, CatalogLearningPathsPath } from 'navigation/Routes'
import SimilarCourses from 'components/Course/SimilarCourses'
import { amazonBucket, TwitterShareLink, LinkdinShareLink, FacebookShareLink } from 'constants/'
import CertificateImage from './CertificateHelpers/CertificateImage'
import { decorateUrl } from 'components/common/UTMscript'
import { formatCertificateDate, formatSecondsDurationFromAlpormJS } from 'helpers/'
import { NameInitials } from 'UI/helpers'

const useStyles = createUseStyles(style)

export default ({ generateCertificate, certificate, userId, fetchCertificateById, isConnected, goToLink, clearCertificate }) => {
  const theme = useTheme()
  const { avatar, paper, cardRow, paper2, certificateStyle, leftCol, socialIconsContainer, desktopHeaderRow, centerIconClass, containerClass, socialIcons } = useStyles()
  const { id } = useParams()
  const isPrivateMode = isConnected && userId === certificate.UserId
  const loading = certificate.isLoading
  useEffect(() => {
    fetchCertificateById(id)
    return () => clearCertificate()
  }, [id])
  return (
    <Layout hideSupport>
      <Container className={containerClass}>
        {loading
          ? <Skeleton height='24px' width='340px' margin='32px 0 13px 0' />
          : (
            <>
              <TypographyElement
                component='h3'
                spacing='64px 0 24px 0'
                spacingMobile='32px 0 16px 0'
                spacingTablet='32px 0 16px 0'
                variant='heading1'
                fontSizeTablet='36px'
                fontSizeMobile='36px'
              >
                {Text.title}
              </TypographyElement>
              <MediaQuery HideOnDesktop>
                {isPrivateMode
                  ? (
                    <Button
                      onHandleClick={() => generateCertificate(certificate)}
                      width='100%'
                      variation='primary'
                      size='small'
                      label={Text.download}
                      loading={loading}
                      key={`dekstop-${loading}`}
                      marginButton='0 0 16px 0'
                    />
                    )
                  : (
                    <Paper noHover className={paper}>
                      <Avatar
                        width={34}
                        height={34}
                        widthTablet={34}
                        heightTablet={34}
                        widthMobile={34}
                        heightMobile={34}
                        img={certificate?.UserImage ? `${amazonBucket.bucketBaseUrl}${certificate.UserImage}` : null}
                        name={!certificate?.UserImage ? NameInitials(`${certificate.UserFirstName} ${certificate.UserLastName}`) : null}
                        className={avatar}
                        loading={loading}
                        key={loading}
                      />
                      {
                    loading
                      ? <Skeleton margin='0 0 0 16px' />
                      : (
                        <TypographyElement
                          Component='p'
                          variant='body1'
                        >
                          {`${certificate.UserFirstName} ${certificate.UserLastName}`}
                        </TypographyElement>)
                  }
                    </Paper>)}
              </MediaQuery>
            </>
            )}
        <MediaQuery HideOnTablet HideOnMobile>
          <Row className={desktopHeaderRow}>
            <Col grid={3} className={leftCol}>
              {
                isPrivateMode
                  ? (
                    <Button
                      onHandleClick={() => generateCertificate(certificate)}
                      width='100%'
                      variation='primary'
                      size='small'
                      label={Text.download}
                      loading={loading}
                      key={`dekstop-${loading}`}
                      marginButton='0 0 16px 0'
                    />
                    )
                  : (
                    <Paper noHover className={paper}>
                      <Avatar
                        width={34}
                        height={34}
                        widthTablet={34}
                        heightTablet={34}
                        widthMobile={34}
                        heightMobile={34}
                        img={certificate?.UserImage ? `${amazonBucket.bucketBaseUrl}${certificate.UserImage}` : null}
                        name={!certificate?.UserImage ? NameInitials(`${certificate.UserFirstName} ${certificate.UserLastName}`) : null}
                        className={avatar}
                        loading={loading}
                        key={loading}
                      />
                      {
                        loading
                          ? <Skeleton margin='0 0 0 16px' />
                          : (
                            <TypographyElement
                              Component='p'
                              variant='body1'
                            >
                              {`${certificate.UserFirstName} ${certificate.UserLastName}`}
                            </TypographyElement>)
                      }
                    </Paper>
                    )
              }
              <Card
                image={certificate.Image}
                rate={certificate.Rating}
                userRated={certificate.ReviewersCount}
                duration={certificate.Duration}
                usersEnrolled={certificate.ViewersCount}
                title={certificate.Title}
                trainerName={`${certificate.AuthorFirstName} ${certificate.AuthorLastName}`}
                handleCardClick={() => goToLink(`${CourseNeutralPath}/${certificate.Slug}`)}
                loading={loading}
                href={`${CourseNeutralPath}/${certificate.Slug}`}
                component='a'
                key={loading}
                height={245}
              />
              <Paper className={paper2}>
                {
                  loading
                    ? (
                      <>
                        <Skeleton height='18px' />
                      </>
                      )
                    : (
                      <>
                        <TypographyElement component='p' variant='body1'>
                          {Text.share}
                        </TypographyElement>
                        <div className={socialIcons}>
                          <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${FacebookShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                            <Icon iconName={Icons.facebook} style={centerIconClass} />
                          </span>
                          <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${TwitterShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                            <Icon iconName={Icons.twitter} style={centerIconClass} />
                          </span>
                          <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${LinkdinShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                            <Icon iconName={Icons.linkedin} style={centerIconClass} />
                          </span>
                        </div>
                      </>
                      )
                }
              </Paper>
            </Col>
            <Col grid={9}>
              <Paper noHover className={certificateStyle}>
                {loading
                  ? <Skeleton height='160px' />
                  : (
                    <CertificateImage
                      name={`${certificate.UserFirstName} ${certificate.UserLastName}`}
                      title={certificate.Title}
                      date={`${Text.certificateTexts.the} ${formatCertificateDate(certificate.CertificationDate)}`}
                      duration={`${Text.certificateTexts.durration} ${formatSecondsDurationFromAlpormJS(certificate.Duration)}`}
                      link={`${window.location.origin}${CertificateNeutralPath}/\n${certificate.Id}`}
                    />)}
              </Paper>
            </Col>
          </Row>
        </MediaQuery>
        <MediaQuery HideOnDesktop>
          <Paper noHover className={certificateStyle}>
            {loading
              ? <Skeleton height='160px' />
              : (
                <CertificateImage
                  name={`${certificate.UserFirstName} ${certificate.UserLastName}`}
                  title={certificate.Title}
                  date={`${Text.certificateTexts.the} ${formatCertificateDate(certificate.CertificationDate)}`}
                  duration={`${Text.certificateTexts.durration} ${formatSecondsDurationFromAlpormJS(certificate.Duration)}`}
                  link={`${window.location.origin}${CertificateNeutralPath}/\n${certificate.Id}`}
                />)}
          </Paper>
          <div>
            <Card
              image={certificate.Image}
              rate={certificate.Rating}
              userRated={certificate.ReviewersCount}
              duration={certificate.Duration}
              usersEnrolled={certificate.ViewersCount}
              title={certificate.Title}
              trainerName={`${certificate.AuthorFirstName} ${certificate.AuthorLastName}`}
              handleCardClick={() => goToLink(`${CourseNeutralPath}/${certificate.Slug}`)}
              loading={loading}
              key={loading}
              height={206}
            />
            <Paper className={paper2}>
              {loading
                ? <Skeleton />
                : (
                  <>
                    <TypographyElement component='p' variant='body2' spacing='0 15px 0 0'>
                      {Text.share}
                    </TypographyElement>
                    <div className={socialIcons}>
                      <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${FacebookShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                        <Icon iconName={Icons.facebook} style={centerIconClass} />
                      </span>
                      <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${TwitterShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                        <Icon iconName={Icons.twitter} style={centerIconClass} />
                      </span>
                      <span className={socialIconsContainer} onClick={() => window.open(decorateUrl(`${LinkdinShareLink}${window.location.origin}${CertificateNeutralPath}/${certificate.Id}`))}>
                        <Icon iconName={Icons.linkedin} style={centerIconClass} />
                      </span>
                    </div>
                  </>)}
            </Paper>
          </div>
        </MediaQuery>
        {isPrivateMode
          ? <SimilarCourses
              title={Text.forYou}
              courseLoading={loading}
              titleProps={{
                spacingTablet: '0 0 24px 0',
                spacingMobile: '0 0 24px 0'
              }}
            />
          : (
            <>
              <TypographyElement
                component='h3'
                spacing='0 0 24px 0'
                spacingMobile='0px 0 24px 0'
                spacingTablet='0px 0 24px 0'
                variant='heading2'
              >
                {Text.exploreCatalog}
              </TypographyElement>
              <Row
                className={cardRow}
                gap={20}
                tabletGap={16}
                mobileGap={16}
                direction='row'
                tabletDirection='column'
                mobileDirection='column'
              >
                <Col grid={6} sgrid={12} mgird={12}>
                  <AdCard
                    title={Text.adCard1.title}
                    core={Text.adCard1.paragraph}
                    buttonLabel={Text.adCard1.action}
                    onButtonClick={() => goToLink(CatalogPath)}
                    shadowColor={theme.primary[0]}
                  />
                </Col>
                <Col grid={6} sgrid={12} mgird={12}>
                  <AdCard
                    title={Text.adCard2.title}
                    core={Text.adCard2.paragraph}
                    buttonLabel={Text.adCard2.action}
                    onButtonClick={() => goToLink(CatalogLearningPathsPath)}
                    shadowColor={theme.extra.violet}
                  />
                </Col>
              </Row>
            </>
            )}
      </Container>
    </Layout>
  )
}
